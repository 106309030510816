import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>15DM App</h1>
        <div className={"panel"}>HELLO</div>
      </header>
    </div>
  );
}

export default App;
